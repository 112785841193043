import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import ImageGallery from 'react-image-gallery';


const images = [

  {
    original: require('./../../assets/images/gallery/1.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail1.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/2.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail2.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/3.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail3.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/4.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail4.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/5.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail5.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/6.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail6.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/7.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail7.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/8.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail8.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/9.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail9.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/10.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail10.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/11.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail11.jpg'),
  },
  {
    original: require('./../../assets/images/gallery/12.jpg'),
    thumbnail: require('./../../assets/images/gallery/thumbnails/thumbnail12.jpg'),
  },
]

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
 

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Music On. <span className="text-color-primary">World Off</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Our multi award winning memory foam eartips utilise class-leading design, engineering and materials to redefine what you thought was possible from your earphones.
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="#crystaltips">
                    Learn More
                    </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
              <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} />
            </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;